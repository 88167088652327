<template>
  <vue-ueditor-wrap v-model="contents" :config="config"></vue-ueditor-wrap>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      contents: "",
      config: {
        //zIndex: 9999,//有层级问题可以设置zIndex
        autoHeightEnabled: false, // 编辑器不自动被内容撑高
        initialFrameHeight: 420, // 初始容器高度
        initialFrameWidth: "100%", // 初始容器宽度
        UEDITOR_HOME_URL: "/UEditor/",
        // 上传文件接口（这个地址是我为了方便各位体验文件上传功能搭建的临时接口，请勿在生产环境使用！！！）
        serverUrl: `/api/upload/bdUpload`, //后端配置的上传接口（接口返回配置只需要一个oobj，不需要外层内容）
        toolbars: [[
          'fullscreen', 'undo', 'redo', '|',
          'bold', 'italic', 'underline', 'fontborder', 'strikethrough', 'superscript', 'subscript', 'removeformat', 'formatmatch', 'blockquote', 'pasteplain', '|', 'forecolor', 'backcolor', 'insertorderedlist', 'insertunorderedlist', 'selectall', 'cleardoc', '|',
          'rowspacingtop', 'rowspacingbottom', 'lineheight', '|',
          'customstyle', 'paragraph', 'fontfamily', 'fontsize', '|',
          'directionalityltr', 'directionalityrtl', 'indent', '|',
          'justifyleft', 'justifycenter', 'justifyright', 'justifyjustify', '|', 'touppercase', 'tolowercase', '|',
          'link', 'unlink', 'anchor', '|', 'imagenone', 'imageleft', 'imageright', 'imagecenter', '|',
          'simpleupload', 'insertimage', '|',
          'horizontal', 'date', 'time', 'spechars', 'snapscreen', '|',
          'inserttable', 'deletetable', 'insertparagraphbeforetable', 'insertrow', 'deleterow', 'insertcol', 'deletecol', 'mergecells', 'mergeright', 'mergedown', 'splittocells', 'splittorows', 'splittocols', 'charts'
        ]],
      },
    };
  },
  created() { },
  watch: {
    value: {
      handler(value) {
        this.contents = value;
      },
      immediate: true,
    },
    contents(value) {
      this.$emit("input", value);
    },
  },
};
</script>
<style lang="less" scoped></style>
